import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text } from "../../components/Core";
import imgAppStore from "../../assets/image/svg/app-store-badge.svg";
import imgPlay from "../../assets/image/png/google-play-badge.png";
import Footer from "../../components/Footer";
import { device } from "../../utils";
import { HelmetTitle } from "../../components/HelmetTitle";

const APP_STORE_LINK =
  "https://apps.apple.com/app/apple-store/id1505368956?pt=120203676&ct=invite&mt=8";
const PLAY_STORE_LINK =
  "https://play.google.com/store/apps/details?id=co.jomolabs.payday&referrer=utm_source%3Dinvite%26utm_medium%3Dwebsite";

const FillHeightBox = styled(Box)`
  @media (min-height: 1000px) {
    height: 100vh;
  }
`;

const SectionStyled = styled(Section)`
  border-bottom: 1px solid #eae9f2;
`;

const ButtonContainer = styled(Box)`
  margin-top: 35px;
  display: flex;
  justify-content: center;
`;

const ButtonApp = styled.a`
  padding: 0 4px;
  transition: 0.4s;
  &:visited {
    text-decoration: none;
  }
  &:hover {
    transform: translateY(-8px);
  }

  @media ${device.sm} {
    padding: 0 9px;
  }
`;

const AppStoreImg = styled.img`
  height: 50px;

  @media ${device.sm} {
    height: 80px;
  }
`;

const redirectTo404 = () => {
  window.location.href = "/404";
};

const InviteCodePage = ({ params }) => {
  const inviteCode = params.inviteCode;
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkInviteCode = async () => {
      if (!inviteCode) {
        redirectTo404();
      }
      try {
        const response = await fetch(
          `https://api.allo.finance/inviteCode/${inviteCode.toUpperCase()}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          setIsChecking(false);
        } else {
          redirectTo404();
        }
      } catch (e) {
        redirectTo404();
      }
    };

    checkInviteCode();
  }, [inviteCode]);

  if (isChecking) {
    return null;
  }

  return <InviteCode inviteCode={params.inviteCode} />;
};

const InviteCode = ({ inviteCode }) => (
  <FillHeightBox display="flex" flexDirection="column">
    <HelmetTitle title={`Allo — Invite Code: ${inviteCode.toUpperCase()}`} />
    <SectionStyled
      display="flex"
      flexDirection="column"
      flex="1"
      justifyContent="center"
    >
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="7" xl="6">
            <div className="text-center">
              <Title>Your Free Trial is Ready!</Title>
              <Text>
                We are excited for you to begin your mindful money practice with
                Allo. Our Early Access Program has closed, but you can now
                download the app to get full access to Allo with a 14-day free
                trial.
              </Text>
            </div>
            <ButtonContainer>
              <ButtonApp href={APP_STORE_LINK} target="_blank">
                <AppStoreImg src={imgAppStore} alt="App Store" />
              </ButtonApp>
              <ButtonApp href={PLAY_STORE_LINK} target="_blank">
                <AppStoreImg src={imgPlay} alt="Play Store" />
              </ButtonApp>
            </ButtonContainer>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
    <Footer isDark={true} />
  </FillHeightBox>
);

export default InviteCodePage;
